import { IImageProps } from "../Image/Image";

export enum ButtonComponent {
  DEFAULT,
  LINK,
}

export type Design = "default" | "line" | "underline";
export type Color = "default" | "secondary" | "transparent";
export type Type = "button" | "submit";

export const sizeClassMap = {
  default: "w-full lg:w-[300px] max-w-full",
  "fit-content": "w-fit",
};

export type Size = keyof typeof sizeClassMap;

export interface IButton {
  id: string;
  name: string;
  color?: Color;
  design?: Design;
}

export interface ILink {
  id: string;
  name: string;
  to: string;
  color?: Color;
  design?: Design;
  testId?: string;
  keepQueryParams?: boolean;
}

export interface IHeaderLinkChild extends IHeaderLink {
  image?: IImageProps;
  tag?: string;
  seeMoreText?: string;
}

export type HeaderLinkType = "default" | "cta" | "authMenu" | "logout";

export interface IHeaderLink extends ILink {
  image?: IImageProps;
  children?: IHeaderLinkChild[];
  type?: HeaderLinkType;
  withAssociatedDiscount?: boolean;
}

type DesignClassStyle = {
  [key in Color]: string;
};

export interface IDesignClassStyleWithBase extends DesignClassStyle {
  base: string;
}

type DesignClassMap = {
  [key in Design]: IDesignClassStyleWithBase
};

export const designClassMap: DesignClassMap = {
  default: {
    base: "data-[disabled=true]:!bg-dark-grey data-[disabled=true]:pointer-events-none disabled:bg-dark-grey disabled:text-white disabled:cursor-not-allowed block min-w-fit h-10 lg:min-h-[50px] flex justify-center items-center px-3 lg:px-6 py-2 uppercase rounded-full text-center font-display opacity-100 lg:hover:opacity-70 transition-opacity duration-300",
    default: "bg-black text-white lg:theme-header:bg-light-grey lg:theme-header:text-black theme-brand:bg-white theme-brand:text-black theme-dark:bg-light-grey theme-dark:text-black theme-footer:bg-white theme-footer:text-black theme-brand:theme-light:bg-black theme-brand:theme-light:text-white",
    secondary: "text-white theme-brand-alt:bg-white theme-brand-alt:text-black theme-brand-alt:theme-brand:bg-black theme-brand-alt:theme-light-grey:text-white theme-brand-alt:theme-light-grey:bg-black theme-brand-alt:theme-brand:text-white theme-brand:bg-black theme-brand:text-white theme-brand:disabled:bg-dark-grey",
    transparent: "text-black",
  },
  line: {
    base: "disabled:opacity-30 disabled:cursor-not-allowed block min-w-fit h-10 lg:min-h-[50px] flex justify-center items-center border block px-4 lg:px-6 py-2 uppercase rounded-full text-center font-display opacity-100 lg:hover:opacity-70 transition-opacity duration-300",
    default: "text-black border-black",
    secondary: "text-white border-white",
    transparent: "text-black",
  },
  underline: {
    base: "disabled:opacity-30 disabled:cursor-not-allowed block min-w-fit h-10 lg:min-h-[50px] flex justify-center items-center block px-4 lg:px-6 py-2 underline text-center font-display opacity-100 lg:hover:opacity-70 transition-opacity duration-300",
    default: "text-black",
    secondary: "text-white border-white",
    transparent: "text-black",
  },
};
